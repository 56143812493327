.wrapper {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.title {
	text-align: center;
	font-size: 24px;
	font-weight: 700;
}
