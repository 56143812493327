.title {
	font-weight: 700;
	font-size: 20px;
}

.list {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 12px;
	margin-top: 12px;
}

.item {
	position: relative;
	border-radius: 10px;
	padding: 18px 16px;
	background: var(--bg-color);

	transition: transform 0.3s ease;

	display: flex;
	flex-direction: column;
	gap: 8px;

	&.hover {
		cursor: pointer;
		&:active {
			transform: scale(1.06);
		}
	}

	.special {
		padding: 2px 8px;
		position: absolute;
		top: 0;
		right: 0;

		border-bottom-left-radius: 8px;
		border-top-right-radius: 8px;

		font-weight: 500;
		font-size: 11px;
		text-transform: uppercase;
		color: white;
	}

	&.platinum {
		border: 1px solid #076bd7;

		& .special {
			background: linear-gradient(90deg, #1a3b5f 0%, #007aff 100%);
		}
	}

	&.gold {
		border: 1px solid #db9f45;

		& .special {
			background: linear-gradient(90deg, #7e5923 0%, #ebab4a 100%);
		}
	}

	&.silver {
		border: 1px solid #ccc;

		& .special {
			background: linear-gradient(90deg, #828282 0%, #ccc 100%);
		}
	}

	&.bronze {
		border: 1px solid #a5724b;

		& .special {
			background: linear-gradient(90deg, #754726 0%, #a5724b 100%);
		}
	}
}

.name {
	font-weight: 600;
	font-size: 14px;

	display: flex;

	align-items: center;
	gap: 8px;
}

.image {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: var(--text-color);

	overflow: hidden;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.percent {
	font-weight: 700;
	font-size: 20px;
	color: var(--green-color);

	display: flex;
	align-items: center;
	gap: 4px;
}

.days {
	font-weight: 400;
	font-size: 14px;
	color: var(--hint-color);

	p {
		color: var(--link-color);
		font-weight: 500;
	}
}

.deposit {
	font-weight: 400;
	font-size: 14px;
	color: var(--hint-color);

	p:last-child {
		font-weight: 500;
		color: var(--text-color);
	}
}

.delete {
	position: absolute;
	top: 10px;
	right: 10px;
	color: var(--red-color);
	font-weight: 700;

	width: 20px;
	height: 20px;

	img {
		width: 100%;
		object-fit: contain;
	}
}

.popup {
	display: flex;
	align-items: center;
	flex-direction: column;
	gap: 16px;
}

.popup_icon {
	width: 60px;
	height: 60px;

	border-radius: 50%;
	background: var(--hint-color);

	display: flex;
	align-items: center;
	justify-content: center;

	path {
		stroke: white;
	}
}

.popup_title {
	font-weight: 600;
	font-size: 20px;
	text-align: center;
	color: var(--text-color);
}

.popup_info {
	align-self: stretch;
	width: 100%;
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: var(--hint-color);

	display: flex;
	flex-direction: column;
	gap: 8px;
}

.popup_info_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;

	font-weight: 400;
	font-size: 16px;

	p {
		color: var(--text-color);
		opacity: 0.5;
	}

	&.bold {
		p {
			font-weight: 500;
			opacity: 1;
		}
	}
}
