.button {
	font-weight: 600;
	font-size: 17px;
	line-height: 1.3;

	background: var(--btn-color);
	color: var(--btn-text-color);
	text-align: center;

	padding: 15px 12px;
	border-radius: 10px;

	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;

	transition: box-shadow 0.3s ease;
	box-shadow: inset 0 0 -2px 20px transparent;

	@media (hover: hover) and (pointer: fine) {
		&:hover {
			box-shadow: inset 0 0 10px -5px #000;
		}
	}

	&:active {
		box-shadow: inset 0 0 14px -3px #000;
	}
}

.small {
	padding: 8px 12px;
}

.disabled {
	cursor: default;
	pointer-events: none;
	opacity: 0.5;
}

.white {
	color: var(--btn-color);
	background: var(--btn-text-color);
}

.red {
	background: var(--red-color);
	color: var(--btn-text-color);
}