.title {
	font-weight: 700;
	font-size: 20px;
	text-align: center;
}

.content {
	margin: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	gap: 10px;

	padding-bottom: 60px;
}

.item {
	display: flex;
	align-items: center;
	gap: 20px;
}

.label {
	flex: 0 0 200px;
	font-size: 16px;
	font-weight: 600;
}

.status {
	margin: 20px 0 0 0;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.status_item {
	display: grid;
	grid-template-columns: 60px 1fr;
	gap: 10px;
}

.status_inputs {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px 5px;
}

.status_input_label {
	font-size: 12px;
}
