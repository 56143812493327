.wrapper {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.title {
	font-weight: 600;
	font-size: 17px;

	display: flex;
	align-items: center;
	gap: 4px;
}

.percent {
	padding: 0 6px;
	border-radius: 8px;
	font-weight: 300;
	font-size: 14px;
	color: var(--hint-color);
	background: #f5f5f8;

	&.color_white {
		color: #fff;
	}
}

.days {
	font-weight: 400;
	font-size: 14px;
	color: var(--hint-color);
}

.status_line_background {
	width: 100%;
	height: 6px;
	border-radius: 100px;
	background: rgba(239, 239, 244, 0.6);
	position: relative;
}

.status_line {
	position: absolute;
	inset: 0;
	border-radius: 100px;
	background: rgba(0, 0, 0, 0.6);
}

.bronze {
	background: linear-gradient(90deg, #754726 0%, #a5724b 100%);
}

.silver {
	background: linear-gradient(90deg, #828282 0%, #ccc 100%);
}

.gold {
	background: linear-gradient(90deg, #7e5923 0%, #ebab4a 100%);
}

.platinum {
	background: linear-gradient(90deg, #1a3b5f 0%, #007aff 100%);
}

.popup {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.popup_info {
	font-weight: 400;
	font-size: 16px;

	display: flex;
	flex-direction: column;
	gap: 12px;
}

.popup_item {
	display: flex;
	align-items: center;
	gap: 10px;
}

.item_icon {
	border-radius: 10px;
	padding: 8px;
	background: #f5f5f8;

	display: flex;
	align-items: start;
	justify-content: center;
}

.error {
	font-weight: 400;
	font-size: 14px;
	color: var(--red-color);

	display: flex;
	align-items: center;
	gap: 4px;
}

.success {
	font-weight: 400;
	font-size: 14px;
	color: var(--green-color);

	display: flex;
	align-items: center;
	gap: 4px;
}
