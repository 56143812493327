.chart {
	width: 100%;
	padding: 16px;
	border-radius: 10px;

	background: var(--bg-color);

	display: flex;
	flex-direction: column;
	gap: 12px;

	&_label {
		color: var(--hint-color);
		font-size: 14px;
		font-weight: 400;
		line-height: 1.5;
		letter-spacing: -0.4px;
	}

	&_value {
		margin: 4px 0 0 0;
		font-size: 20px;
		font-weight: 700;
	}

	&_rate {
		margin: 4px 0 0 0;
		font-size: 16px;
		font-weight: 700;
		color: var(--green-color);
	}

	&_buttons {
		display: flex;
		align-items: center;
		gap: 8px;
	}

	&_btn {
		width: 45px;
		text-align: center;
		font-size: 14px;
		font-weight: 400;
		line-height: 1.6;
		letter-spacing: -0.4px;
		padding: 5px 0;
		border-radius: 10px;
		background: var(--secondary-bg-color);
		color: var(--text-color);

		transition:
			color 0.3s ease,
			background 0.3s ease;

		&.active {
			background: var(--link-color);
			color: #fff;
		}
	}
}

.tooltip {
	padding: 6px;
	border: 6px;
	background: var(--bg-color);

	&_label {
		font-size: 14px;
		color: var(--link-color);
		font-weight: 500;
	}

	&_value {
		margin: 6px 0 0 0;
		font-weight: 600;
		font-size: 16px;
	}
}
