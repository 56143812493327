.wrapper {
	position: fixed;
	z-index: 1000;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;

	background: rgba($color: #000000, $alpha: 0.4);

	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	opacity: 0;
	pointer-events: none;

	transition: opacity 0.6s ease;

	&.show {
		opacity: 1;
		pointer-events: all;
	}
}

.content {
	padding: 16px 16px 48px;
	border-radius: 20px 20px 0px 0px;
	background: var(--bg-color);

	transform: translateY(150%);

	transition: transform 0.3s ease-in-out;

	position: relative;
}

.show .content {
	transform: translateY(0);
}

.close {
	cursor: pointer;
	position: absolute;
	top: 16px;
	right: 16px;
}
