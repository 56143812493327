.wrapper {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.title {
	font-weight: 600;
	font-size: 20px;
	text-align: center;
}

.steps {
	display: flex;
	gap: 12px;
}

.step {
	padding: 14px;
	border-radius: 10px;
	background: var(--bg-color);

	display: flex;
	flex-direction: column;
	gap: 8px;

	&_title {
		font-weight: 600;
		font-size: 17px;
		color: var(--link-color);
	}

	&_info {
		font-weight: 400;
		font-size: 14px;
		letter-spacing: -0.03em;
		color: var(--text-color);
		opacity: 0.5;
	}
}

.link {
	border-radius: 10px;
	padding: 16px;
	background: var(--bg-color);

	display: flex;
	flex-direction: column;
	gap: 8px;

	&_title {
		font-weight: 600;
		font-size: 17px;
		color: var(--text-color);
	}

	&_value {
		cursor: pointer;

		white-space: nowrap;
		border-radius: 10px;
		padding: 11px 12px;
		background: var(--secondary-bg-color);

		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 12px;

		overflow: hidden;
	}

	&_icon {
		width: 16px;
		height: 16px;
		flex: 0 0 16px;
	}
}

.info_block {
	display: flex;
	gap: 12px;
}

.info {
	border-radius: 10px;
	padding: 14px 16px;
	background: var(--bg-color);

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 10px;

	min-width: 115px;

	&:nth-child(2) {
		flex: 1 1 auto;
	}

	&_title {
		font-weight: 400;
		font-size: 14px;

		line-height: 1.57;
		letter-spacing: -0.03em;
		color: var(--text-color);
		opacity: 0.5;
	}

	&_value {
		font-weight: 700;
		font-size: 20px;
		color: var(--text-color);
	}

	&_percent {
		border-radius: 8px;
		padding: 0px 12px;

		font-weight: 600;
		font-size: 20px;
		color: var(--text-color);
		background: var(--hint-color);

		span {
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.actions {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.list {
	border-radius: 10px;
	padding: 8px;
	background: var(--bg-color);

	display: flex;
	flex-direction: column;
	gap: 4px;
}

.item {
	padding: 8px;

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.block_1 {
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.name {
	font-weight: 400;
	font-size: 14px;
	color: var(--text-color);
}

.date {
	font-weight: 400;
	font-size: 14px;
	color: var(--text-color);
	opacity: 0.5;
}

.block_2 {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 2px;
}

.sum {
	display: flex;
	align-items: center;
	gap: 2px;

	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: var(--green-color);
}

.action {
	font-weight: 400;
	font-size: 14px;

	color: var(--text-color);
	opacity: 0.5;

	&.green {
		color: var(--green-color);
		opacity: 1;
	}
}
