.wrapper {
	width: 100%;
	background: var(--bg-color);
	padding: 10px;
	border-radius: 10px;

	display: flex;
	justify-content: space-between;
	gap: 20px;
}

.title {
	font-family: "Share Tech Mono", monospace;
	font-size: 14px;
	background: transparent;
	color: var(--text-color);
}

.date {
	margin: 4px 0 0 0;
	font-size: 14px;
	color: var(--hint-color);
}

.sum {
	font-size: 16px;
}

.status {
	text-align: right;
	margin: 2px 0 0 0;
	font-size: 14px;
	color: var(--hint-color);

	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 2px;
}

.green {
	color: var(--green-color);
}

.progress {
	color: #cc830d;
}
